/**
 * This is our own flavour of moment library with localisations applied.
 */

/**
 * Moment
 *
 * TODO: We should not be exposing moment directly, we should expose functions that
 *       create, parse, format etc. This should be part of the dates module.
 */
import {getMomentJsLocale, getActiveFarmTimezone} from "Figured/Assets/Modules/globals";

import moment from "moment-timezone";
import "moment-timezone/builds/moment-timezone-with-data-2012-2022";
import "moment/locale/en-au";
import "moment/locale/en-nz";


moment.locale(getMomentJsLocale());
moment.tz.setDefault(getActiveFarmTimezone());

export default moment;
